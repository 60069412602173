import React, { PureComponent } from 'react'

import data from './data/map'
import './App.css'

export default class App extends PureComponent {
    state = { items: data.items }

    updateItems = id => {
        const items = JSON.parse(JSON.stringify(this.state.items))
        const index = items.findIndex(item => item.id === id)
        items.map((item, i) => {
            if (index === i) item.active = !item.active
            else item.active = false
        })
        this.setState({ items })
    }

    onClick = id => this.updateItems(id)

    onImageClick = id => this.updateItems(id)

    onListClick = (e, id) => {
        e.preventDefault()
        this.updateItems(id)
    }

    render() {
        const { items } = this.state
        return (
            <div className="position-relative map-container">
                <div className="w-100 h-100 map-images">
                    <img className="img-fluid" src={data.bg.fix} alt="Map" />
                    {items.map(({ id, active, src, title }) => (
                        <div
                            key={id}
                            id={id}
                            className="position-absolute h-auto map-image"
                        >
                            <img
                                key={id}
                                className={`w-100 h-100 ${
                                    active ? 'active' : ''
                                }`}
                                src={src}
                                alt={title}
                                onClick={() => this.onClick(id)}
                            />
                            {active && (
                                <div className="card position-absolute shadow-sm animated fadeIn fast">
                                    <div className="card-body p-2 text-center">
                                        {title}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="card position-absolute shadow map-legends">
                    <h3 className="card-header">Map Legend</h3>
                    <ul className="list-group list-group-flush">
                        {items.map(({ active, id, title }) => (
                            <li
                                className={`list-group-item list-group-item-action ${
                                    active ? 'active' : ''
                                }`}
                                onClick={() => this.onClick(id)}
                            >
                                {title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}
