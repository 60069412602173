export default {
    bg: {
        fix: require('../assets/images/map_bg.jpg'),
        preview: require('../assets/images/map_preview.jpg')
    },
    items: [
        {
            id: 'mushola',
            title: 'Mushola',
            src: require('../assets/images/mushola.png'),
            // style: { width: '72px', top: '264px', left: '634px' },
            active: false
        },
        {
            id: 'ticketBox',
            title: 'Ticket Box',
            src: require('../assets/images/ticket_box.png'),
            active: false
        },
        {
            id: 'sarnafil1',
            title: 'Sarnafil 1',
            src: require('../assets/images/sarnafil.png'),
            active: false
        },
        {
            id: 'sarnafil2',
            title: 'Sarnafil 2',
            src: require('../assets/images/sarnafil.png'),
            active: false
        },
        {
            id: 'sarnafil3',
            title: 'Sarnafil 3',
            src: require('../assets/images/sarnafil.png'),
            active: false
        }
    ]
}
